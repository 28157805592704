<template>
  <header class="shadow-lg border-secondary border-t-2 z-20">
    <div class="container">
      <div class="flex flex-row justify-between">
        <div class="flex flex-col justify-center">

          <button
            class="lg:hidden focus:outline-none"
            @click="openNavMobile"
          >
            <img class="w-5" src="/img/bars--blue.svg"/>
          </button>

          <teleport to="#nav-mobile">
            <nav class="nav-mobile lg:hidden flex flex-col justify-center items-center" v-if="showNavMobile">
              <button
                class="absolute top-2 right-4 px-4 py-2 focus:outline-none"
                @click="closeNavMobile"
              >
                <img class="w-5" src="/img/times--blue.svg"/>
              </button>
              <div v-for="(it, idx) in navList" :key="`nav-item-${idx}`">

                <router-link v-if="it.route" :to="it.route" @click="closeNavMobile">
                  <div class="mx-3 py-3 cursor-pointer font-bold text-primary" v-html="it.label" />
                </router-link>

                <div v-else class="parent-item mx-3 py-3 cursor-pointer text-primary" @click="activateSubNav(idx)">
                  <span class="font-bold" v-html="it.label"/>
                  <div class="sub-nav" :class="{ active: isSubNavActive[idx] }">
                    <div class="sub-nav-item" v-for="(itt, idxx) in it.items" :key="`subnav-item-${idx}-${idxx}`">
                      
                      <router-link v-if="itt.route" :to="itt.route" @click="closeNavMobile">
                        <div class="cursor-pointer text-center text-primary hover:font-bold" v-html="itt.label" />
                      </router-link>

                    </div>
                  </div>
                </div>

              </div>
            </nav>
          </teleport>

        </div>
        <router-link :to="{ name: 'Home' }">
          <img
            style="width:112px;"
            class="mx-auto mt-2 mb-4"
            alt="Logo"
            src="/img/logo-friso-header.png"
            srcset="/img/logo-friso-header.png,
              /img/logo-friso-header@2x.png 2x,
              /img/logo-friso-header@3x.png 3x"
          />
        </router-link>
        <div class="flex flex-col justify-center text-right cursor-pointer">
          <button class="focus:outline-none relative"  @click="toggleUserMenu" @mouseleave="hideUserMenu">
            <img class="w-6" src="/img/user-circle--blue.svg" />
            <div class="user-menu -mr-4" v-if="showUserMenu">
              <div
                class="my-2 cursor-pointer text-xs uppercase font-extrabold
                  text-primary bg-gray-100 rounded py-2 px-4 shadow"
                @click="logout"
              >
                Salir
              </div>
            </div>
          </button>
        </div> 
      </div>
      <nav class="desktop-nav hidden lg:flex flex-row justify-between align-middle">
        <div v-for="(it, idx) in navList" :key="`nav-item-${idx}`">
          <router-link v-if="it.route" :to="it.route">
            <div class="leading-10 cursor-pointer font-bold text-primary" v-html="it.label" />
          </router-link>
          <div v-else class="leading-10 cursor-pointer font-bold text-primary parent-item whitespace-nowrap">
            <span v-html="it.label"/>
            <div class="sub-nav">
              <div
                class="sub-nav-item w-full border-primary border bg-white 
                  hover:bg-primary my-1 px-3 rounded"
                v-for="(itt, idxx) in it.items"
                :key="`subnav-item-${idxx}`"
              >
                <router-link v-if="itt.route" :to="itt.route">
                  <div class="leading-10 mx-3 cursor-pointer font-bold text-primary hover:text-white" v-html="itt.label" />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Header",
  setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    const navList = [
      { route: { name: "Home" }, label: "Inicio" },
      { route: { name: "Portfolio" }, label: "Portafolio" },
      { label: "Calculadoras&nbsp;de&nbsp;crecimiento", items: [
        { route: { name: "Calculator", params: { slug: "head-circumference-age-zero-to-five" } }, label: "Perímetro cefálico" },
        { route: { name: "Calculator", params: { slug: "height-age-two-to-five" } }, label: "Estatura" },
        { route: { name: "Calculator", params: { slug: "lenght-age-zero-to-two" } }, label: "Longitud" },
        { route: { name: "Calculator", params: { slug: "weight-age-zero-to-five" } }, label: "Peso" },
        { route: { name: "Calculator", params: { slug: "weight-height-two-to-five" } }, label: "Peso&nbsp;-&nbsp;Estatura" },
        { route: { name: "Calculator", params: { slug: "weight-length-zero-to-two" } }, label: "Peso&nbsp;-&nbsp;Longitud" },
      ] },
      { label: "Biblioteca", items: [
        { route: { name: "Publications" }, label: "Publicaciones" },
        { route: { name: "ScientificBrochures" }, label: "Folletos científicos" },
        { route: { name: "VideosLibrary" }, label: "Vídeos" },
      ] },
      { label: "Materiales&nbsp;para&nbsp;papás", items: [
        { route: { name: "Poop" }, label: "Heces&nbsp;Saludables" },
        { route: { name: "ComplementaryFeeding" }, label: "Alimentación&nbsp;complementaria" },
        { route: { name: "Lactation" }, label: "Lactancia" },
        { route: { name: "VideosMaterials" }, label: "Vídeos" },
      ] },
      { route: { name: "EasyTracking" }, label: "FácilRastreo" },
      { route: { name: "About" }, label: "Acerca&nbsp;de&nbsp;Friso" },
    ];

    const logout = () => {
      dispatch("logout");
      router.push({ name: "Login" });
    }

    // ---- MOBILE NAV ---- //
    const showNavMobile = ref(false);
    const isSubNavActive = ref(new Array(navList.length));
    const activateSubNav = (idx: number) => {
      for (let i = 0; i < isSubNavActive.value.length; i++) {
        if (i === idx) isSubNavActive.value[i] = !isSubNavActive.value[i];
        else isSubNavActive.value[i] = false;
      }
    };
    const openNavMobile = () => {
      showNavMobile.value = true;
    };
    const closeNavMobile = () => {
      showNavMobile.value = false;
    };
    // ---- /MOBILE NAV ---- //

    const showUserMenu = ref(false);
    const toggleUserMenu = () => {
      showUserMenu.value = !showUserMenu.value;
    };
    const hideUserMenu = () => {
      showUserMenu.value = false;
    };

    return {
      navList,
      showNavMobile,
      openNavMobile,
      closeNavMobile,
      logout,
      isSubNavActive,
      activateSubNav,
      toggleUserMenu,
      showUserMenu,
      hideUserMenu,
    };
  }
});
</script>

<style scoped lang="scss">
.router-link-active {
  div {
    @apply text-secondary;
  }
}

.user-menu {
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 100;
}

.desktop-nav {

  .parent-item {
    position: relative;
    
    .sub-nav {
      position: absolute;
      top: 100%;
      right: 0%;
      // width: 100%;
      display: none;
    }
  
    &:after {
      content: "";
      display: inline-block;
      width: 14px;
      height: 16px;
      background-image: url("../assets/img/icon-chevron-down--blue.svg");
      background-repeat: no-repeat;
      background-position: center center;
      vertical-align: sub;
      margin-left: 6px;
    }
  
    &:hover {
  
      .sub-nav {
        display: block;
      }
    }
  }
}

.nav-mobile {
  position: fixed;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(6px);
  background-color:rgba(255,255,255,.8);

  .parent-item {
    position: relative;
    text-align: center;

    .sub-nav {
      transition: all .3s ease-out;
      height: 0px;
      overflow: hidden;

      // .sub-nav-item {
        
      // }
      &.active {
        height: 100%;
      }
    }

    // &:hover {
    //   .sub-nav {
    //     height: 100%;
    //   }
    // }
  }
}
</style>
