<template>
  <section class="flex flex-col h-screen justify-between">
    <app-header/>
    <main class="flex-1">
      <slot/>
    </main>
    <app-footer/>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  name: "LayoutDefault",
  components: {
    "app-header": Header,
    "app-footer": Footer
  }
});
</script>
