
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  setup () {
    const currentYear = new Date().getFullYear();
    return { currentYear };
  }
});
