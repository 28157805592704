<template>
  <footer class="gradient-bg">
    <div class="container pt-10 pb-4 text-white">
      <div class="mb-8 text-sm text-center">
        Material exclusivo para el profesional de la salud. La lactancia materna es el mejor alimento para el sano crecimiento y desarrollo del lactante.
      </div>
      <img
        style="width:112px;"
        class="mx-auto mb-2"
        alt="Logo"
        src="/img/logo-friso-footer.png"
        srcset="/img/logo-friso-footer.png,
          /img/logo-friso-footer@2x.png 2x,
          /img/logo-friso-footer@3x.png 3x"
      />
      <div class="font-bold text-xl text-center mb-6">¡Más fuerte por dentro para poder experimentar más juntos!</div>
      <div class="font-bold text-center mb-2">Encuéntranos en:</div>
      <div class="mb-10 text-center">
        <a class="inline-block" href="#facebook-url">
          <img class="h-6 mx-3" alt="Facebook" title="Facebook" src="/img/logo-brand-facebook--white.svg"/>
        </a>
        <a class="inline-block" href="#youtube-url">
          <img class="h-6 mx-3" alt="Youtube" title="Youtube" src="/img/logo-brand-youtube--white.svg"/>
        </a>
        <a class="inline-block" href="#twitter-url">
          <img class="h-6 mx-3" alt="Twitter" title="Twitter" src="/img/logo-brand-twitter--white.svg"/>
        </a>
        <a class="inline-block" href="#instagram-url">
          <img class="h-6 mx-3" alt="Instagram" title="Instagram" src="/img/logo-brand-instagram--white.svg"/>
        </a>
      </div>
      <div class="text-sm font-bold text-center">&copy; {{ currentYear }} FrieslandCampina, todos los derechos reservados.</div>
      <div class="text-xs text-center">El contenido de esta página web, incluyendo, pero no limitado a nuestras marcas principales FRISO<sup>&reg;</sup> y FRISOLAC<sup>&reg;</sup>, es propiedad intelectual protegida de FrieslandCampina.</div>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "Footer",
  setup () {
    const currentYear = new Date().getFullYear();
    return { currentYear };
  }
});
</script>

<style scoped lang="scss">
  .gradient-bg {
    background: rgb(0,67,139);
    background: radial-gradient(circle at bottom center, rgb(0,67,139) 0%, rgba(0,38,92,1) 100%);
  }
</style>
