import { markRaw } from "vue";
import { createStore } from "vuex";
import auth from "@/providers/auth";
import LayoutDefault from "@/layouts/Default.vue";
import LayoutBlank from "@/layouts/Blank.vue";
import { getStoragePortfolioAgreed, setStoragePortfolioAgreed } from "@/providers/storage";

export default createStore({
  state: {
    showPortfolioAgreementPopup: true,
    isUserAuthenticated: false,
    layout: markRaw(LayoutDefault)
  },
  getters: {
    showPortfolioAgreementPopup: state => {
      state.showPortfolioAgreementPopup = !getStoragePortfolioAgreed();
      return state.showPortfolioAgreementPopup;
    },
    isUserAuthenticated: state => state.isUserAuthenticated,
    layout: state => state.layout
  },
  mutations: {
    agreePortfolio: state => {
      state.showPortfolioAgreementPopup = false;
      setStoragePortfolioAgreed();
    },
    setDefaultLayout: state => {
      state.layout = markRaw(LayoutDefault);
    },
    setBlankLayout: state => {
      state.layout = markRaw(LayoutBlank);
    },
    userAuthentication: (state, val: boolean) => {
      state.isUserAuthenticated = val;
    }
  },
  actions: {
    checkUserAuthentication: ({ commit }) => {
      const isUserAuthenticated = auth.isUserAuthenticated();
      commit("userAuthentication", isUserAuthenticated);
    },
    login: async (
      { commit },
      { username, password }
    ): Promise<any[] | void> => {
      return new Promise((resolve, reject) => {
        auth
          .login(username, password)
          .then(() => {
            commit("userAuthentication", true);
            resolve();
          })
          .catch(err => {
            reject(err.errors);
          });
      });
    },
    logout: ({ commit }) => {
      auth.logout();
      commit("userAuthentication", false);
    }
  },
  modules: {}
});
