<template>
  <section class="flex flex-col h-screen justify-between">
    <main class="flex-1">
      <slot/>
    </main>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "LayoutBlank",
});
</script>
