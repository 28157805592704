
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default defineComponent({
  name: "Header",
  setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    const navList = [
      { route: { name: "Home" }, label: "Inicio" },
      { route: { name: "Portfolio" }, label: "Portafolio" },
      { label: "Calculadoras&nbsp;de&nbsp;crecimiento", items: [
        { route: { name: "Calculator", params: { slug: "head-circumference-age-zero-to-five" } }, label: "Perímetro cefálico" },
        { route: { name: "Calculator", params: { slug: "height-age-two-to-five" } }, label: "Estatura" },
        { route: { name: "Calculator", params: { slug: "lenght-age-zero-to-two" } }, label: "Longitud" },
        { route: { name: "Calculator", params: { slug: "weight-age-zero-to-five" } }, label: "Peso" },
        { route: { name: "Calculator", params: { slug: "weight-height-two-to-five" } }, label: "Peso&nbsp;-&nbsp;Estatura" },
        { route: { name: "Calculator", params: { slug: "weight-length-zero-to-two" } }, label: "Peso&nbsp;-&nbsp;Longitud" },
      ] },
      { label: "Biblioteca", items: [
        { route: { name: "Publications" }, label: "Publicaciones" },
        { route: { name: "ScientificBrochures" }, label: "Folletos científicos" },
        { route: { name: "VideosLibrary" }, label: "Vídeos" },
      ] },
      { label: "Materiales&nbsp;para&nbsp;papás", items: [
        { route: { name: "Poop" }, label: "Heces&nbsp;Saludables" },
        { route: { name: "ComplementaryFeeding" }, label: "Alimentación&nbsp;complementaria" },
        { route: { name: "Lactation" }, label: "Lactancia" },
        { route: { name: "VideosMaterials" }, label: "Vídeos" },
      ] },
      { route: { name: "EasyTracking" }, label: "FácilRastreo" },
      { route: { name: "About" }, label: "Acerca&nbsp;de&nbsp;Friso" },
    ];

    const logout = () => {
      dispatch("logout");
      router.push({ name: "Login" });
    }

    // ---- MOBILE NAV ---- //
    const showNavMobile = ref(false);
    const isSubNavActive = ref(new Array(navList.length));
    const activateSubNav = (idx: number) => {
      for (let i = 0; i < isSubNavActive.value.length; i++) {
        if (i === idx) isSubNavActive.value[i] = !isSubNavActive.value[i];
        else isSubNavActive.value[i] = false;
      }
    };
    const openNavMobile = () => {
      showNavMobile.value = true;
    };
    const closeNavMobile = () => {
      showNavMobile.value = false;
    };
    // ---- /MOBILE NAV ---- //

    const showUserMenu = ref(false);
    const toggleUserMenu = () => {
      showUserMenu.value = !showUserMenu.value;
    };
    const hideUserMenu = () => {
      showUserMenu.value = false;
    };

    return {
      navList,
      showNavMobile,
      openNavMobile,
      closeNavMobile,
      logout,
      isSubNavActive,
      activateSubNav,
      toggleUserMenu,
      showUserMenu,
      hideUserMenu,
    };
  }
});
