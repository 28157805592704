<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "App",
  setup() {
    const { getters, dispatch } = useStore();

    const layout = computed(() => getters.layout);

    dispatch("checkUserAuthentication");

    return { layout };
  }
});
</script>
